import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ActionDialog, Alert, Copy, Field, Input, Modal, Select } from '@hexure/ui';
import { mdiFileDownloadOutline, mdiEmailFastOutline, mdiForumOutline, mdiChevronRight, mdiCheckboxMarkedCircleOutline, mdiOpenInNew } from '@mdi/js';

import ActionCard from 'shared/ActionCard';

import { ThemeContext } from 'shared/ThemeContext';

import ApiUtils from 'utils/AnnuitiesApi';
import FormUtils from 'utils/Form';

const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 40px;
`;

const Layouts = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  cursor: pointer;
`;

const PdfPreview = styled.div`
  width: 700px;
  height: 476px;
  border-radius: 8px;
  background: #f5f5f5;
  border-width: 2px;
  border-style: solid;
  border-color: ${props => (props.$isSelected ? props.$color : '#fff')};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.$image});
`;

const InfoContent = styled.div`
  width: 800px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Form = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
`;

const Column = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FieldGroup = styled.div`
  display: flex;
  padding: 18px 18px 0;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #fcfcfc;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;

const ComparisonModal = ({ agent, client, onClose, selectedQuotes }) => {
  const { color } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState({
    delivery_method: 'download',
    template: 'demo',
    agent_email: agent?.email || '',
    agent_first_name: agent?.first_name || '',
    agent_last_name: agent?.last_name || '',
    agent_phone: agent?.phone || '',
    agent_license: agent?.license || '',
    first_name: client?.first_name || '',
    last_name: client?.last_name || '',
    quote_guids: selectedQuotes,
    phone: '',
    email: '',
    subject: 'Your Annuities Quote',
    message: 'Thank you for your interest! Take a look at your personalized annuities quote.'
  });
  const [page, setPage] = useState('delivery_method');
  const [pdf_url, setPdfUrl] = useState(null);
  const titles = {
    delivery_method: 'Select a Delivery Method',
    template: 'Select a PDF Layout',
    information: 'Enter Information'
  };
  const setDeliveryMethod = delivery_method => {
    setData({
      ...data,
      delivery_method
    });
  };
  const setLayout = template => {
    setData({
      ...data,
      template
    });
  };
  const handleInputChange = (name, e) => {
    setData({
      ...data,
      [name]: e.target.value
    });
  };
  const handlePdfTemplate = (field, selectedValue) => {
    setData(prevData => ({
      ...prevData,
      [field]: selectedValue
    }));
  };
  const handleSubmit = () => {
    const new_errors = [];
    const { phone, email, subject, message, delivery_method, first_name, last_name, ...payload } = data;

    if (delivery_method === 'email') {
      if (!FormUtils._validateEmail(payload.agent_email)) {
        new_errors.push('agent_email');
      }

      if (!FormUtils._validateEmail(email)) {
        new_errors.push('email');
      }

      if (!subject) {
        new_errors.push('subject');
      }

      if (!message) {
        new_errors.push('message');
      }
    }

    if (delivery_method === 'text') {
      if (!FormUtils._validatePhone(phone)) {
        new_errors.push('phone');
      }
      if (!message) {
        new_errors.push('message');
      }
    }

    if (new_errors.length) {
      setErrors(new_errors);
    } else {
      setLoading(true);

      if (delivery_method === 'email') {
        payload.from = payload.agent_email;
        payload.to = email;
        payload.subject = subject;
        payload.message = message;
      }

      if (payload.delivery_method === 'text') {
        payload.phone_number = phone;
        payload.message = message;
      }

      ApiUtils.generateQuotesPdf({
        ...payload,
        client_name: `${first_name} ${last_name}`,
        pdf_action: delivery_method,
        phone_number: data?.phone || '',
        message: data?.message || '',
        show_agent_info: true
      })
        .then(({ data }) => {
          setLoading(false);
          setPdfUrl(data.url);
          setPage('success');
        })
        .catch(() => {
          setErrors(['api_error']);
          setTimeout(() => {
            setErrors([]);
          }, 3000);
        });
    }
  };
  const buttons = {
    delivery_method: {
      secondaryButton: {
        children: 'Cancel',
        onClick: onClose
      },
      primaryButton: {
        children: 'Next',
        icon: mdiChevronRight,
        onClick: setPage.bind(null, 'template')
      }
    },
    template: {
      secondaryButton: {
        children: 'Back',
        onClick: setPage.bind(null, 'delivery_method')
      },
      primaryButton: {
        children: 'Next',
        icon: mdiChevronRight,
        onClick: setPage.bind(null, 'information')
      }
    },
    information: {
      secondaryButton: {
        children: 'Back',
        disabled: loading,
        onClick: setPage.bind(null, 'template')
      },
      primaryButton: {
        children: data.delivery_method === 'download' ? `${loading ? 'Creating PDF...' : 'Create PDF'}` : `${loading ? 'Sending...' : 'Send'}`,
        icon: mdiCheckboxMarkedCircleOutline,
        loading,
        onClick: handleSubmit
      }
    }
  };

  return page === 'success' ? (
    <ActionDialog
      description={
        data.delivery_method === 'download'
          ? 'Your quote comparison PDF is ready. Click the button below to download it.'
          : 'Your quote comparison PDF has been sent. Click the button below to view the PDF that was sent.'
      }
      primaryButton={{
        children: 'View PDF',
        icon: mdiOpenInNew,
        onClick: () => window.open(pdf_url)
      }}
      secondaryButton={{
        children: 'Back to Results',
        onClick: onClose
      }}
      title='Success!'
    />
  ) : (
    <Modal onClose={onClose} title={titles[page]} {...buttons[page]}>
      {page === 'delivery_method' ? (
        <Actions>
          <ActionCard icon={mdiFileDownloadOutline} isSelected={data.delivery_method === 'download'} onClick={setDeliveryMethod.bind(null, 'download')}>
            Download
          </ActionCard>
          <ActionCard icon={mdiEmailFastOutline} isSelected={data.delivery_method === 'email'} onClick={setDeliveryMethod.bind(null, 'email')}>
            Email
          </ActionCard>
          <ActionCard icon={mdiForumOutline} isSelected={data.delivery_method === 'text'} onClick={setDeliveryMethod.bind(null, 'text')}>
            Text
          </ActionCard>
        </Actions>
      ) : null}

      {page === 'template' ? (
        <Layouts>
          <Layout $color={color} onClick={setLayout.bind(null, 'demo')}>
            <PdfPreview $color={color} $image='https://ixntestsa001.blob.core.windows.net/ixn/images/standard.png' $isSelected={data.template === 'demo'} />
            <Copy margin='0px auto' type='bold'>
              Income Comparison Report
            </Copy>
          </Layout>
          <Layout $color={color} onClick={setLayout.bind(null, 'annuity_quoter_features')}>
            <PdfPreview $color={color} $image='https://ixntestsa001.blob.core.windows.net/ixn/images/clementine_by_feature.png' $isSelected={data.template === 'annuity_quoter_features'} />
            <Copy margin='0px auto' type='bold'>
              Features Report
            </Copy>
          </Layout>
          <Layout $color={color} onClick={setLayout.bind(null, 'annuity_quoter_bonus')}>
            <PdfPreview $color={color} $image='https://ixntestsa001.blob.core.windows.net/ixn/images/clementine_by_premium.png' $isSelected={data.template === 'annuity_quoter_bonus'} />
            <Copy margin='0px auto' type='bold'>
              Bonus Comparison Report
            </Copy>
          </Layout>
          <Layout $color={color} onClick={setLayout.bind(null, 'annuity_quoter_accumulation')}>
            <PdfPreview $color={color} $image='https://ixntestsa001.blob.core.windows.net/ixn/images/clementine_condensed.png' $isSelected={data.template === 'annuity_quoter_accumulation'} />
            <Copy margin='0px auto' type='bold'>
              Accumulation Report
            </Copy>
          </Layout>
        </Layouts>
      ) : null}

      {page === 'information' ? (
        <InfoContent>
          {errors.includes('api_error') ? (
            <Alert description='An error occurred while trying to generate the PDF. If it continues to happen, please contact support.' title='Something went wrong' type='error' />
          ) : null}
          <Form>
            <Column>
              <Copy type='bold'>Agent Information</Copy>
              <FieldGroup>
                <Row>
                  <Field label='First Name' style={{ width: 120 }}>
                    <Input onChange={handleInputChange.bind(null, 'agent_first_name')} readOnly={loading} value={data.agent_first_name} />
                  </Field>
                  <Field label='Last Name' style={{ flex: 1 }}>
                    <Input onChange={handleInputChange.bind(null, 'agent_last_name')} readOnly={loading} value={data.agent_last_name} />
                  </Field>
                </Row>
                <Field label='Email' required={data.delivery_method === 'email'} style={{ width: '100%' }}>
                  <Input invalid={errors.includes('agent_email')} onChange={handleInputChange.bind(null, 'agent_email')} readOnly={loading} value={data.agent_email} />
                </Field>
                <Field label='Phone' style={{ width: '100%' }}>
                  <Input format='phone' onChange={handleInputChange.bind(null, 'agent_phone')} readOnly={loading} value={data.agent_phone} />
                </Field>
                <Field label='License Number' style={{ width: '100%' }}>
                  <Input onChange={handleInputChange.bind(null, 'agent_license')} readOnly={loading} value={data.agent_license} />
                </Field>
              </FieldGroup>
            </Column>
            <Column>
              <Copy type='bold'>Recipient Information</Copy>
              <FieldGroup>
                <Row>
                  <Field label='Last Name' style={{ width: 120 }}>
                    <Input onChange={handleInputChange.bind(null, 'first_name')} readOnly={loading} value={data.first_name} />
                  </Field>
                  <Field label='Last Name' style={{ flex: 1 }}>
                    <Input onChange={handleInputChange.bind(null, 'last_name')} readOnly={loading} value={data.last_name} />
                  </Field>
                </Row>
                <Field label='Select Pdf Template' required={true} style={{ width: '100%' }}>
                  <Select
                    onChange={e => handlePdfTemplate('template', e.target.value)}
                    options={[
                      { label: 'demo', value: 'demo' },
                      { label: 'annuity_quoter_accumulation', value: 'annuity_quoter_accumulation' },
                      { label: 'annuity_quoter_pages', value: 'annuity_quoter_pages' }
                    ]}
                    required={true}
                    value={data.template}
                  />
                </Field>
                {data.delivery_method === 'email' ? (
                  <>
                    <Field label='Email' required={true} style={{ width: '100%' }}>
                      <Input invalid={errors.includes('email')} onChange={handleInputChange.bind(null, 'email')} readOnly={loading} value={data.email} />
                    </Field>
                    <Field label='Subject' required={true} style={{ width: '100%' }}>
                      <Input invalid={errors.includes('subject')} onChange={handleInputChange.bind(null, 'subject')} readOnly={loading} value={data.subject} />
                    </Field>
                    <Field label='Message' required={true} style={{ width: '100%' }}>
                      <Input
                        height={140}
                        invalid={errors.includes('message')}
                        onChange={handleInputChange.bind(null, 'message')}
                        readOnly={loading}
                        style={{ width: '100%' }}
                        type='textarea'
                        value={data.message}
                      />
                    </Field>
                  </>
                ) : null}

                {data.delivery_method === 'text' ? (
                  <>
                    <Field label='Phone' required={true} style={{ width: '100%' }}>
                      <Input format='phone' invalid={errors.includes('phone')} onChange={handleInputChange.bind(null, 'phone')} readOnly={loading} value={data.phone} />
                    </Field>
                    <Field label='Message' required={true} style={{ width: '100%' }}>
                      <Input
                        height={140}
                        invalid={errors.includes('message')}
                        onChange={handleInputChange.bind(null, 'message')}
                        readOnly={loading}
                        style={{ width: '100%' }}
                        type='textarea'
                        value={data.message}
                      />
                    </Field>
                  </>
                ) : null}
              </FieldGroup>
            </Column>
          </Form>
        </InfoContent>
      ) : null}
    </Modal>
  );
};

ComparisonModal.propTypes = {
  agent: PropTypes.object,
  client: PropTypes.object,
  solve: PropTypes.object,
  onClose: PropTypes.func,
  selectedQuotes: PropTypes.array
};

export default ComparisonModal;
