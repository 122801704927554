import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Cookies from 'js-cookie';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';
import Numeral from 'numeral';
import { mdiCalculator, mdiChevronRight, mdiProgressCheck } from '@mdi/js';
import { Copy, ZeroState, Alert } from '@hexure/ui';
import _orderBy from 'lodash/orderBy';

import Loader from 'shared/Loader';

import ComparisonModal from 'annuities/ComparisonModal';
import CriteriaCard from 'annuities/CriteriaCard';
import CriteriaModal from 'annuities/CriteriaModal';
import ResultsBar from 'annuities/ResultsBar';
import LoadingQuote from 'annuities/LoadingQuote';
import QuoteCard from 'annuities/QuoteCard';
import QuoteActionModal from 'annuities/QuoteActionModal';

import ApiUtils from 'utils/AnnuitiesApi';
import FormUtils from 'utils/Form';

import { ProductContext } from 'annuities/Context';
import { ThemeContext } from 'shared/ThemeContext';

import { Colors } from 'constants/Clementine';

import { STATES, AMBEST_SORTING } from 'constants/App';
import VisualizeModal from '../components/annuities_quoter/VisualizeModal';
import { isEqual } from 'lodash';

window.IXN = {};
const IXN_AUTH = Cookies.get('ixn') ? Cookies.getJSON('ixn') : null;
//const COOKIED_CRITERIA = Cookies.getJSON('hexure_annuities');
const QUOTER_CONFIG = window.HEXURE_AQ_CONFIG;

const Widget = styled.div`
  display: flex;
  flex-direction: ${props => (props.$layout === 'horizontal' ? 'row' : 'column')};
  gap: 20px;
`;

const Criteria = styled.div`
  display: flex;
  gap: 20px;
  width: ${props => (props.$layout === 'horizontal' ? '100%' : 'auto')};
  flex-direction: ${props => (props.$layout === 'horizontal' ? 'column' : 'row')};
  flex-shrink: 0;
`;

const Results = styled.div`
  display: flex;
  gap: 20px;
  flex: 1;
  flex-direction: ${props => (props.$layout === 'horizontal' ? 'row' : 'column')};
`;

const Quotes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const EmptyState = styled.div`
  width: 100%;
  padding: 60px 0;
  border-radius: 8px;
  border: 1px dashed '#ff0000';
  background: #fff;
`;

const EmptyStateContent = styled.div`
  width: 100%;
  margin: 0 auto;
`;

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : null;
}

const AnnuitiesQuoter = () => {
  const layout = 'vertical';
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState(IXN_AUTH);
  const [carriers, setCarriers] = useState([]);
  const [products, setProducts] = useState([]);
  const [products_features, setProductsFeatures] = useState([]);
  const [quoter, setQuoter] = useState(null);
  const [error, setError] = useState(null);
  const [criteriaOptions, setCriteriaOptions] = useState({
    default_state: 'AL',
    states: STATES,
    product_types: [
      { value: 'Fixed Indexed Annuity' },
      { value: 'Fixed Annuity' },
      { value: 'Multi-Year Guaranteed Annuity' },
      { value: 'Single Premium Immediate Annuity' },
      { value: 'Deferred Income Annuity' }
    ]
  });
  const [criteriaTab, setCriteriaTab] = useState('agent');
  const [aaq, setAAQ] = useState(null);
  const [modal, setModal] = useState(null);
  const [agent, setAgent] = useState(null);
  const [trackAgent, setTrackAgent] = useState(null);
  const [trackClient, setTrackClient] = useState(null);
  const [trackSolve, setTrackSolve] = useState(null);
  const [trackProduct, setTrackProduct] = useState(null);
  const [agentErrors, setAgentErrors] = useState([]);
  const [client, setClient] = useState(null);
  const [clientErrors, setClientErrors] = useState([]);
  const [product, setProduct] = useState(null);
  const [productErrors, setProductErrors] = useState([]);
  const [solve, setSolve] = useState(null);
  const [solveErrors, setSolveErrors] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [oldAgent, setOldAgent] = useState(null);
  const [oldClient, setOldClient] = useState(null);
  const [oldProduct, setOldProduct] = useState(null);
  const [oldSolve, setOldSolve] = useState(null);
  const [visible_quotes, setVisibleQuotes] = useState([]);
  const [loadingQuotes, setLoadingQuotes] = useState(false);
  const [selectedQuotes, setSelectedQuotes] = useState([]);
  const [quote, setQuote] = useState(null);
  const [paginationQuotes, setPaginationQuotes] = useState([]);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [loadedQuotes, setLoadedQuotes] = useState([]);
  const [quoteCount, setQuoteCount] = useState(3);
  const [filters, setFilters] = useState({
    sort_by: solve?.solve_for === 'Premium' ? 'premium' : solve?.solve_for === 'Accumulation' ? 'accumulation' : 'monthly_income',
    sort_dir: 'asc',
    filter_by: solve?.solve_for === 'Premium' ? 'premium' : solve?.solve_for === 'Accumulation' ? 'accumulation' : 'monthly_income',
    filter_low: 0,
    filter_high: 1000000,
    filter_range: [0, 1000000],
    filter_values: [],
    search: ''
  });
  const first_view = !client && !product && !solve;
  const invalid_criteria = agentErrors.length || clientErrors.length || productErrors.length || solveErrors.length;
  const theme_context = {
    color: quoter?.theme_options?.secondary_color || Colors.BLUE.hex
  };
  const product_context = {
    auth: IXN_AUTH
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const showCriteria = tab => {
    setModal('criteria');
    setCriteriaTab(tab);
  };

  const loadMember = () => {
    if (auth?.id) {
      ApiUtils.loadMember(auth.id).then(({ data }) => {
        applyCriteria(
          {
            first_name: data.first_name || agent?.first_name,
            last_name: data.last_name || agent?.last_name,
            email: data.email || agent?.email,
            phone: data.phone || agent?.phone,
            insurance_license_number: data.insurance_license_number || agent?.insurance_license_number
          },
          client,
          product,
          solve
        );
      });
    }
  };

  const validateCriteria = (group, name, value) => {
    let is_valid = true;

    if (group === 'agent') {
      if (['first_name', 'last_name', 'insurance_license_number'].includes(name) && !value) {
        is_valid = false;
      }

      if (name === 'email' && !FormUtils._validateEmail(value)) {
        is_valid = false;
      }

      if (name === 'phone' && !FormUtils._validatePhone(value)) {
        is_valid = false;
      }
    }

    if (group === 'client') {
      if (['first_name', 'last_name', 'current_age'].includes(name) && !value) {
        is_valid = false;
      }
    }

    if (group === 'product') {
      if (name === 'carrier_ids' && !value?.length) {
        is_valid = false;
      }
    }

    if (group === 'solve') {
      if (['gmb_start_year', 'start_year_offset'].includes(name) && !value) {
        is_valid = false;
      }
      if (['premium', 'income'].includes(name) && (!value || value === '0')) {
        is_valid = false;
      }
    }

    return is_valid;
  };

  const addCusipsToQuotes = (products, quotes) => {
    return quotes?.map(quote => {
      const quoted_product = products?.find(product => quote.product_id === product.id);

      return { ...quote, product_cusip: quoted_product.cusip };
    });
  };
  const addEmbedConfigQuoteActions = quotes => {
    const embed_config_quote_actions = [];

    QUOTER_CONFIG.quote_actions.forEach(quote_action => {
      const enabled_quote_guids = [];

      quotes.forEach(quote => {
        if (quote_action.should_show(quote)) {
          enabled_quote_guids.push(quote.guid);
        }
      });

      embed_config_quote_actions.push({ ...quote_action, enabled_quote_guids });
    });

    setAAQ(prev_state => {
      return { ...prev_state, embed_config_quote_actions };
    });
  };

  const applyCriteria = (agent, client, product, solve) => {
    if (agent && Object.keys(agent)?.length) {
      const errors = [];

      ['first_name', 'last_name', 'email', 'phone', 'insurance_license_number'].forEach(name => {
        if (!validateCriteria('agent', name, agent[name])) {
          errors.push(name);
        }
      });

      setAgent(agent);
      setAgentErrors(errors);
    }

    if (client && Object.keys(client)?.length) {
      const errors = [];

      ['first_name', 'last_name'].forEach(name => {
        if (!validateCriteria('client', name, client[name])) {
          errors.push(name);
        }
      });

      setClient(client);
      setClientErrors(errors);
    }

    if (product && Object.keys(product)?.length) {
      const errors = [];

      ['carrier_ids'].forEach(name => {
        if (!validateCriteria('product', name, product[name])) {
          errors.push(name);
        }
      });

      if (product?.product_types && ['Fixed Annuity', 'Fixed Indexed Annuity'].includes(product.product_types[0])) {
        delete product.guaranteed_period;
        delete product.market_value_adjustment;
      }

      if (product?.product_types && ['Multi-Year Guaranteed Annuity'].includes(product.product_types[0])) {
        delete product.surrender_charge_period;
        delete product.income_start_date;
        delete product.income_payment_mode;
        delete product.payment_option;
      }

      if (product?.product_types && ['Single Premium Immediate Annuity', 'Deferred Income Annuity'].includes(product.product_types[0])) {
        delete product.guaranteed_period;
        delete product.market_value_adjustment;
        delete product.surrender_charge_period;

        // check for at least one option in payment_option
      }

      setProduct(product);
      setProductErrors(errors);
    }

    if (solve && Object.keys(solve)?.length) {
      const errors = [];

      // if (solve.solve_for === 'Premium') {
      //   solve.premium = 0;
      //   if (!validateCriteria('solve', 'income', solve.income)) {
      //     errors.push('income');
      //   }
      // }

      // if (solve.solve_for === 'Income') {
      //   solve.income = 0;
      //   if (!validateCriteria('solve', 'premium', solve.premium)) {
      //     errors.push('premium');
      //   }
      //   if (!validateCriteria('solve', 'gmb_start_year', solve.gmb_start_year) && solve.gmb_start_year === '') {
      //     errors.push('gmb_start_year');
      //   }
      //   if (!validateCriteria('solve', 'start_year_offset', solve.start_year_offset) && solve.start_year_offset === '') {
      //     errors.push('start_year_offset');
      //   }
      // }
      if (!validateCriteria('solve', 'premium', solve.premium)) {
        errors.push('premium');
      }

      setSolve(solve);
      setSolveErrors(errors);
    }
  };

  const getQuotes = () => {
    if (!invalid_criteria && !first_view) {
      const params = {
        ...client,
        ...product,
        ...solve,
        product_ids: products?.filter(p => product?.carrier_ids?.includes(p.carrier_id))?.map(p => p.id),
        product_types: product?.product_types[0] === 'Multi-Year Guaranteed Annuity' ? ['MYGA'] : product?.product_types
      };

      setLoadingQuotes(true);
      setQuotes([]);
      setSelectedQuotes([]);
      setError(null);
      setPaginationQuotes([]);

      ApiUtils.getQuotes(params)
        .then(quotes => {
          const filter_by = solve.solve_for === 'Premium' ? 'premium' : solve.solve_for === 'Accumulation' ? 'accumulation' : 'monthly_income';
          const values = quotes?.map(q => parseInt(q[filter_by], 10));
          const lowest_value = values.length ? Math.floor(Math.min(...values)) : 0;
          const highest_value = values.length ? Math.ceil(Math.max(...values)) + 1 : 1000000;

          setQuotes(quotes);
          setFilters({
            sort_by: solve?.solve_for === 'Premium' ? 'premium' : solve?.solve_for === 'Accumulation' ? 'accumulation' : 'monthly_income',
            sort_dir: 'desc',
            filter_by: solve?.solve_for === 'Premium' ? 'premium' : solve?.solve_for === 'Accumulation' ? 'accumulation' : 'monthly_income',
            filter_low: lowest_value,
            filter_high: highest_value,
            filter_range: [lowest_value, highest_value],
            filter_values: values,
            search: ''
          });
          setLoadingQuotes(false);
          Cookies.set(
            'hexure_annuities',
            {
              agent,
              client,
              product,
              solve
            },
            { domain: window.location.hostname }
          );
          if (QUOTER_CONFIG.quote_actions && QUOTER_CONFIG.quote_actions.length) {
            if (QUOTER_CONFIG.embed_context === 'fire_light') {
              const batchSize = 200;

              const productPromises = [];
              for (let i = 0; i < quotes.length; i += batchSize) {
                const batchIds = quotes.slice(i, i + batchSize).map(q => q.product_id);
                productPromises.push(ApiUtils.loadProduct({ id: batchIds }));
              }
              Promise.all(productPromises)
                .then(responses => {
                  const allProductData = responses.flatMap(response => response);
                  const quotes_with_cusips = addCusipsToQuotes(allProductData, quotes);
                  addEmbedConfigQuoteActions(quotes_with_cusips);
                  setQuotes(quotes_with_cusips);
                  setPaginationQuotes(quotes_with_cusips);
                })
                .catch(error => {
                  // handle errors
                });
            } else {
              addEmbedConfigQuoteActions(quotes);
            }
          }
        })
        .catch(() => {
          // TODO: Handle error response, display error in quote results area
          setError({ type: -1, message: 'No quotes Found' });
          setLoadingQuotes(false);
        });
    }
  };

  const handleQuoteSelect = id => {
    if (selectedQuotes.includes(id)) {
      setSelectedQuotes(selectedQuotes?.filter(q => q !== id));
    } else {
      setSelectedQuotes([...selectedQuotes, id]);
    }
  };

  const handleCompareSelected = () => {
    setModal('comparison');
  };

  const handleShowQuoteActions = quote => {
    setModal('actions');
    setQuote(quote);
  };

  const handleFilterChange = (name, e) => {
    setFilters({
      ...filters,
      [name]: e.target.value ? e.target.value : ''
    });
  };

  const handleAgentLogin = agent => {
    setModal(null);
    setAgent(agent);
    window.IXN.get_quoter_config().agent = agent;
    validateCriteria();
  };

  const handleModal = (modal, product) => {
    setModal(modal);
    setCurrentProduct(product);
  };

  // infinite scroll for the quotessection

  const handleScroll = () => {
    const element = document.getElementById('hexure-annuities-quoter');
    if (element) {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight && !loading) {
        setQuoteCount(prevCount => prevCount + 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading]);

  useEffect(() => {
    if (window?.IXN_QUOTER_CONFIG?.embed_context === 'fire_light') {
      setQuoteCount(10);
    } else {
      setQuoteCount(10);
    }
  }, [paginationQuotes]);

  useEffect(() => {
    // Load initial set of images
    setLoadedQuotes(paginationQuotes.slice(0, quoteCount));
  }, [quoteCount, paginationQuotes]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    ApiUtils.loadQuoter()
      .then(({ data }) => {
        setQuoter(data);
        //Actions for next activity in Firelight
        setAAQ(data);
      })
      .catch(error => {
        setLoading(false);

        if (error.response && error.response.data) {
          setError(error.response.data[0]);
        } else {
          setError({ type: -1, message: 'Please Provide the BGA-Quoter Token' });
        }
      });
  }, []);

  useEffect(() => {
    if (!quoter) {
      return;
    }

    setLoading(true);

    setCriteriaOptions({
      default_state: quoter.default_state || quoter.states[0] || 'AL',
      states: STATES.filter(s => quoter?.states?.includes(s.value)),
      product_types: quoter?.product_types?.length ? criteriaOptions?.product_types?.filter(pt => quoter?.product_types?.includes(pt.value)) : criteriaOptions?.product_types
    });
    const batchSize = 200;

    const productPromises = [];
    const productFeatures = ['Death Benefits', 'Income Benefits', 'Living Benefits', 'Market Value Adjustment', 'Online Application'];

    for (let i = 0; i < quoter.product_ids.length; i += batchSize) {
      const batchIds = quoter.product_ids.slice(i, i + batchSize);
      productPromises.push(ApiUtils.loadProduct({ id: batchIds }));
    }

    Promise.all([ApiUtils.loadCarriers({ id: quoter.carrier_ids }), ApiUtils.loadProductFeatures(), ...productPromises])
      .then(responses => {
        const carrier = responses.shift();
        const product_features = responses?.shift()?.filter(feature => productFeatures?.includes(feature.name));
        const products = responses
          ?.flatMap(innerArray => innerArray)
          ?.map(product => {
            product.features = product?.product_feature_ids?.map(id => {
              return product_features?.find(pf => pf.id === id);
            });
            return product;
          });

        setCarriers(carrier);
        setProducts(products);
        setProductsFeatures(product_features);
        setLoading(false);
      })
      .catch(() => {
        setError({
          type: -1,
          message: 'Unable to load the data needed for the quoter'
        });
        setLoading(false);
      });
  }, [quoter]);

  useEffect(() => {
    const agentDataChanged = !isEqual(agent, oldAgent);

    const clientDataChanged = !isEqual(client, oldClient);

    const productDataChanged = !isEqual(product, oldProduct);

    const solveDataChanged = !isEqual(solve, oldSolve);

    if (agentDataChanged || clientDataChanged || productDataChanged || solveDataChanged) {
      setQuotes([]);
      applyCriteria(agent, client, product, solve);
      if (!invalid_criteria) {
        getQuotes();
      }
      setOldAgent(agent);
      setOldClient(client);
      setOldProduct(product);
      setOldSolve(solve);
    }
  }, [agent, client, product, solve]);

  useEffect(() => {
    loadMember();
  }, [auth]);

  useEffect(() => {
    const filtered_quotes = quotes?.filter(q => {
      const matches_value = q[filters.filter_by] >= filters?.filter_range[0] && q[filters.filter_by] <= filters?.filter_range[1];
      let matches_search = true;

      if (filters.search?.length) {
        const search_string = filters?.search?.toLowerCase();
        matches_search = q.carrier_name?.toLowerCase().includes(search_string) || q.product_name.toLowerCase().includes(search_string);
      }

      return matches_value && matches_search;
    });

    const sorted_quotes = _orderBy(
      filtered_quotes,
      q => {
        switch (filters.sort_by) {
          case 'ambest':
            return AMBEST_SORTING[q.am_best_rating || 'A+']; // TODO: Remove fallback after demo
          case 'base_rate':
            return q.base_rate;
          case 'carrier':
            return q.carrier_name.toLowerCase();
          case 'current_rate':
            return q.current_rate;
          case 'gtd_rate_years':
            return q.gtd_rate_years;
          case 'monthly_income':
            return q.monthly_income;
          case 'accumulation':
            return q.accumulation;
          case 'premium':
            return q.premium;
          case 'surrender_charge':
            return q.surrender_charge;
          default:
            return true;
        }
      },
      [filters.sort_dir]
    );

    setVisibleQuotes(sorted_quotes);
  }, [filters]);

  if (quoter) {
    window.IXN.get_quoter_config = () => {
      return {
        agent: {
          first_name: trackAgent?.first_name || agent?.first_name || '',
          last_name: trackAgent?.last_name || agent?.last_name || '',
          email: trackAgent?.email || agent?.email || '',
          phone: trackAgent?.phone || agent?.phone || '',
          insurance_license_number: trackAgent?.insurance_license_number || agent?.insurance_license_number || ''
        },
        client: {
          first_name: trackClient?.first_name || client?.first_name || '',
          last_name: trackClient?.last_name || client?.last_name || ''
        },
        quote_data: {
          date_of_birth: trackClient?.date_of_birth || client?.date_of_birth || '',
          current_age: trackClient?.current_age || client?.current_age || '',
          gender: trackClient?.gender || client?.gender || 'Male',
          state: trackClient?.state || client?.state || 'AL',
          nearest_age: trackClient?.nearest_age || client?.nearest_age || '',
          product_types: trackProduct?.product_types || product?.product_types || ['Fixed Indexed Annuity'],
          carrier_ids: trackProduct?.carrier_ids || product?.carrier_ids || [],
          premium: trackSolve?.premium || solve?.premium || 0,
          product_features: trackProduct?.features || product?.features || []
        }
      };
    };
    window.IXN.update_quoter_config = data => {
      // AGENT
      if (data.agent) {
        setAgent(prevAgent => ({
          ...prevAgent,
          ...data.agent
        }));
      }
      // CLIENT
      if (data.client || data.quote_data) {
        setClient(prevClient => {
          const newClientData = { ...prevClient };

          if (data.client) {
            Object.assign(newClientData, data.client);
          }

          if (data.quote_data) {
            const { dob_year, dob_month, dob_day, gender, current_age, state, nearest_age } = data.quote_data;

            if (dob_year && dob_month && dob_day) {
              newClientData.date_of_birth = `${dob_year}-${dob_month.padStart(2, '0')}-${dob_day.padStart(2, '0')}`;
            } else {
              newClientData.date_of_birth = '1984-08-27';
            }
            if (current_age !== undefined && current_age !== null) newClientData.current_age = current_age;
            newClientData.gender = gender || 'Male';
            newClientData.state = state || 'AL';
            if (nearest_age !== undefined && nearest_age !== null) newClientData.nearest_age = nearest_age;
          }

          return newClientData;
        });
      }
      // QUOTE DATA
      if (data.quote_data) {
        const { carrier_ids, product_features, premium } = data.quote_data;

        const defaultProductTypes = data?.quote_data?.product_types || ['Fixed Indexed Annuity'];

        setProduct(prevProductData => ({
          ...prevProductData,
          product_types: defaultProductTypes,
          am_best_rating: data.quote_data?.am_best_rating || ['Any'],
          min_initial_rate: data.quote_data?.min_initial_rate || '0.0',
          initial_rate_term: data.quote_data?.initial_rate_term || [0],
          tax_qualification: data.quote_data?.tax_qualification || 'Non-Qualified',
          ...(carrier_ids && { carrier_ids }),
          ...(product_features && { product_features })
        }));
        setSolve(prevSolveData => ({
          ...prevSolveData,
          solve_for: data?.quote_data?.solve_for || 'Income',
          premium: data?.quote_data?.premium || 0,
          income: data?.quote_data?.income || 0
        }));

        // Update solve state
        // if (premium) {
        //   setSolve(prevSolveData => ({
        //     ...prevSolveData,
        //     premium
        //   }));
        // }
      }
    };
    window.IXN.disableQuoter = () => {};
    window.IXN.enableQuoter = () => {};
  }

  return loading ? (
    <Loader />
  ) : (
    <ThemeProvider theme={{ PRIMARY_COLOR: { Hex: theme_context.color, Rgb: hexToRgb(theme_context.color) } }}>
      <ThemeContext.Provider value={theme_context}>
        <ProductContext.Provider value={{ ...product_context, aaq, agent, client, annuities: quoter, handleAgentLogin }}>
          {/* {!error && <Notification color={theme_context.color} message='Welcome to Annuities Quoter' />}
          {error && <Notification color={'red'} message={error.message} />} */}
          {modal === 'criteria' ? (
            <CriteriaModal
              agent={agent}
              agentErrors={agentErrors}
              authenticated={!!auth}
              carriers={carriers}
              client={client}
              clientErrors={clientErrors}
              criteriaOptions={criteriaOptions}
              onApply={applyCriteria}
              onClose={setModal.bind(null, null)}
              product={product}
              setTrackProduct={setTrackProduct}
              productErrors={productErrors}
              productsFeatures={products_features}
              setTrackAgent={setTrackAgent}
              setAuth={setAuth}
              setTrackClient={setTrackClient}
              solve={solve}
              setTrackSolve={setTrackSolve}
              solveErrors={solveErrors}
              tab={criteriaTab}
              validateCriteria={validateCriteria}
            />
          ) : null}

          {modal === 'comparison' ? <ComparisonModal agent={agent} client={client} onClose={setModal.bind(null, null)} selectedQuotes={selectedQuotes} /> : null}

          {modal === 'actions' ? (
            <QuoteActionModal
              actions={quoter.quote_actions}
              onClose={() => {
                setModal(null);
                setQuote(null);
              }}
              quote={quote}
              quoteData={{ agent, client, product, solve }}
            />
          ) : null}

          {modal === 'visualize' ? <VisualizeModal onClose={setModal.bind(null, null)} product={currentProduct} quote={quote} /> : null}
          <Widget $layout={layout}>
            <Criteria $layout={windowWidth < 900 ? 'horizontal' : 'vertical'}>
              <CriteriaCard errorCount={agentErrors.length} onClick={showCriteria.bind(null, 'agent')} title='Agent Info'>
                {agent ? (
                  <>
                    <Copy color={agentErrors.includes('first_name') || agentErrors.includes('last_name') ? 'RED' : 'BLACK'}>
                      {!agent.first_name || !agent.last_name ? 'Missing Name' : `${agent.first_name} ${agent.last_name}`}
                    </Copy>
                    <Copy color={agentErrors.includes('email') ? 'RED' : 'BLACK'}>{agent.email || 'Email Missing'}</Copy>
                    <Copy color={agentErrors.includes('phone') ? 'RED' : 'BLACK'}>{agent.phone ? FormUtils._formatAsPhone(agent.phone) : 'Phone Missing'}</Copy>
                    <Copy color={agentErrors.includes('insurance_license_number') ? 'RED' : 'BLACK'}>{agent.insurance_license_number || 'License Missing'}</Copy>
                  </>
                ) : null}
              </CriteriaCard>
              <CriteriaCard errorCount={clientErrors.length} onClick={showCriteria.bind(null, 'client')} title='Client Info'>
                {client ? (
                  <>
                    <Copy color={clientErrors.includes('first_name') || clientErrors.includes('last_name') ? 'RED' : 'BLACK'}>
                      {!client.first_name || !client.last_name ? 'Missing Name' : `${client.first_name} ${client.last_name}`}
                    </Copy>
                    <Copy>{client.date_of_birth || `${client?.current_age} (${client?.nearest_age})`}</Copy>
                    <Copy>{client.gender}</Copy>
                    <Copy>{client.state}</Copy>
                  </>
                ) : null}
              </CriteriaCard>
              <CriteriaCard errorCount={productErrors.length} onClick={showCriteria.bind(null, 'product')} title='Product Criteria'>
                {product ? (
                  <>
                    <Copy>{product.product_types ? product.product_types[0] : null}</Copy>
                    <Copy color={productErrors.includes('carrier_ids') ? 'RED' : 'BLACK'}>{product?.carrier_ids?.length ? `${product.carrier_ids.length} Carriers` : 'Missing Carriers'}</Copy>
                    <Copy>{product.am_best_rating}</Copy>
                    <Copy>{product.features ? `${product.features.length} Features` : 'No Features'}</Copy>
                    <Copy>{product.tax_qualification}</Copy>
                  </>
                ) : null}
              </CriteriaCard>
              <CriteriaCard errorCount={solveErrors.length} onClick={showCriteria.bind(null, 'solve')} title='Solve Data'>
                {solve ? (
                  <>
                    <Copy>Solve For {solve.solve_for}</Copy>
                    <Copy color={solveErrors.includes('premium') ? 'RED' : 'BLACK'}>{Numeral(solve.premium).format('$0,0') || 'Contribution Missing'}</Copy>
                    <Copy>Quote Value At: {solve?.quote_value ? `${solve.quote_value} Year` : '1 Year'}</Copy>
                  </>
                ) : null}
              </CriteriaCard>
            </Criteria>
            <Results>
              <ResultsBar
                disabled={!quotes.length}
                filters={filters}
                onCompare={handleCompareSelected}
                onFilter={handleFilterChange}
                resultsCount={quotes.length || 0}
                selectedCount={selectedQuotes.length}
                setPaginationQuotes={setPaginationQuotes}
                setSelectedQuotes={setSelectedQuotes}
                selectedQuotes={selectedQuotes}
                paginationQuotes={paginationQuotes}
                setQuoteCount={setQuoteCount}
                solveFor={solve?.solve_for}
                visible_quotes={visible_quotes || []}
              />
              {first_view || invalid_criteria ? (
                <EmptyState>
                  <EmptyStateContent>
                    {first_view ? (
                      <ZeroState
                        action={{
                          children: 'Start Entering Criteria',
                          icon: mdiChevronRight,
                          onClick: showCriteria.bind(null, 'agent')
                        }}
                        description='Click into the boxes above to enter your quote criteria. Results will appear in this area once the required criteria has been entered.'
                        icon={mdiCalculator}
                        title='Getting Started'
                      />
                    ) : (
                      <ZeroState
                        action={{
                          children: 'Edit Criteria',
                          icon: mdiChevronRight,
                          onClick: showCriteria.bind(null, 'agent')
                        }}
                        description='More information is needed before quotes can be displayed'
                        icon={mdiProgressCheck}
                        title='Almost There...'
                      />
                    )}
                  </EmptyStateContent>
                </EmptyState>
              ) : (
                <>
                  {loadingQuotes && !error ? (
                    <Quotes>
                      <LoadingQuote key='loading-1' />
                      <LoadingQuote key='loading-2' />
                    </Quotes>
                  ) : (
                    <>
                      {visible_quotes.length && selectedQuotes.length ? (
                        <Alert
                          description='This quote is non-binding and subject to change. A quote is only official after an application is submitted to an insurance company, they have approved you for a policy, and they state it is official.'
                          title='Disclosure'
                          type='warning'
                        />
                      ) : null}

                      {paginationQuotes.length ? (
                        <Quotes>
                          {loadedQuotes?.map((q, index) => {
                            const carrier = carriers?.find(c => c.id === q.carrier_id);
                            const carrier_product = products?.find(p => p.id === q.product_id);

                            return (
                              <motion.div animate={{ opacity: 1, y: 0 }} initial={{ opacity: 0, y: 50 }} key={`${q.product_id} - ${index}`} transition={{ delay: index * 0.1 }}>
                                <QuoteCard
                                  carrier={carrier}
                                  hasActions={!!quoter.quote_actions.find(a => a.carrier_ids.includes(q.carrier_id) && a.product_ids.includes(q.product_id))}
                                  isSelected={selectedQuotes.includes(q.id)}
                                  modal={handleModal}
                                  onSelect={handleQuoteSelect}
                                  selectedQuotes={selectedQuotes}
                                  onShowActions={handleShowQuoteActions}
                                  product={carrier_product}
                                  quote={q}
                                  solveFor={solve.solve_for}
                                  productType={product?.product_types[0]}
                                />
                              </motion.div>
                            );
                          })}
                        </Quotes>
                      ) : (
                        <EmptyState>
                          <EmptyStateContent>
                            <ZeroState
                              action={{
                                children: 'Adjust Criteria',
                                icon: mdiChevronRight,
                                onClick: showCriteria.bind(null, 'product')
                              }}
                              description='Adjust the criteria above to run a new search.'
                              icon={mdiCalculator}
                              title='No Matching Quotes'
                            />
                          </EmptyStateContent>
                        </EmptyState>
                      )}
                    </>
                  )}
                </>
              )}
            </Results>
          </Widget>
        </ProductContext.Provider>
      </ThemeContext.Provider>
    </ThemeProvider>
  );
};

ReactDOM.render(<AnnuitiesQuoter />, document.getElementById('hexure-annuities-quoter'));
