import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Modal from 'shared/Modal';
import Button from 'shared/Button';

import { FontSizes, FontWeights } from 'constants/Clementine';

const OpenUrlModal = ({ data, inline, onClose, url }) => {
  const styles = {
    iframe: {
      height: '100%',
      width: '100%',
      border: 'none'
    },
    success_wrapper: {
      textAlign: 'center',
      padding: 40
    },
    success_title: {
      fontSize: FontSizes.XLARGE,
      fontWeight: FontWeights.MEDIUM,
      marginBottom: 20
    },
    success_description: {
      fontSize: FontSizes.MEDIUM,
      color: '#777',
      marginBottom: 40,
      lineHeight: '1.4em'
    }
  };

  useEffect(() => {
    if (!inline) {
      openUrl();
    }
  }, [url]);

  const openUrl = () => {
    let url_with_params = url;

    if (data) {
      const query_array = Object.keys(data).map(key => {
        const value = data[key];
        const nested_object_keys = Object.keys(value);

        if (nested_object_keys?.length) {
          const nested_strings = nested_object_keys.map(k => {
            return `${key}_${k}=${encodeURIComponent(value[k])}`;
          });

          return nested_strings.join('&');
        } else {
          return `${key}=${encodeURIComponent(data[key])}`;
        }
      });

      url_with_params += `?${query_array.join('&')}`;
    }

    window.open(url_with_params);
  };

  return (
    <Modal fullScreen={inline} maxWidth={inline ? null : 600} onClose={onClose}>
      {inline ? (
        <iframe src={url} style={styles.iframe} />
      ) : (
        <div style={styles.success_wrapper}>
          <div style={styles.success_title}>Success!</div>
          <div style={styles.success_description}>The link should have been opened in a new tab. If it wasn't click the button below.</div>
          <Button inline={true} onClick={openUrl}>
            Open Link
          </Button>
        </div>
      )}
    </Modal>
  );
};

OpenUrlModal.propTypes = {
  data: PropTypes.object,
  inline: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired
};

export default OpenUrlModal;
