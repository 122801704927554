import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React from 'react';

import { ProductContext } from 'annuities/Context';

import Button from 'shared/Button';
import Input from 'shared/Input';
import Field from 'shared/Field';

import { Colors, FontFamilies, FontSizes, FontWeights } from 'constants/Clementine';

import AnnuitiesApi from 'utils/AnnuitiesApi';
import FormUtils from 'utils/Form';

const PASSWORD_RULES = [
  { name: 'numbers', pattern: /(.*[0-9]){2}/, label: 'At least 2 numbers' },
  { name: 'uppercase', pattern: /[A-Z]/, label: 'At least 1 uppercase letter' },
  { name: 'lowercase', pattern: /[a-z]/, label: 'At least 1 lowercase letter' },
  { name: 'special_char', pattern: /[!#$%&()*+,-.\/:;<=>?@[\]^_`{|}~]/, label: 'At least 1 special character' },
  { name: 'length', pattern: /.{8,}/, label: 'At least 8 total characters' }
];

class AgentLoginForm extends React.Component {
  static propTypes = {
    loginDescription: PropTypes.node,
    onLogin: PropTypes.func
  };

  constructor(props, context) {
    super(props);

    this.state = {
      current_view: 'login',
      email: context?.agent?.email || '',
      password: '',
      password_confirmation: '',
      first_name: context?.agent?.first_name || '',
      last_name: context?.agent?.last_name || '',
      phone: context?.agent?.phone || '',

      account_exists: false,
      login_error: false,
      saving: false,
      create_account_errors: PASSWORD_RULES.map(r => r.name)
    };
  }

  _toggleView = current_view => {
    this.setState({
      current_view,
      email: '',
      password: '',
      password_confirmation: ''
    });
  };

  _handleInputChange = (name, e) => {
    this.setState({
      [name]: e.target.value
    });
  };

  _login = () => {
    const { email, password } = this.state;

    AnnuitiesApi.login({ email, password })
      .then(response => {
        // TODO: what if they have an account, but not with the agency?
        // This doesn't matter right now because we don't pull anything agency specific
        // from the logged in user..YET
        Cookies.set(
          'ixn',
          {
            access_token: response.headers['access-token'],
            client: response.headers['client'],
            uid: response.headers['uid'],
            id: response.data.id
          },
          { domain: window.location.hostname }
        );

        this._handleLogin(response.data);
      })
      .catch(e => {
        console.warn(e);
        this.setState({
          login_error: true
        });

        setTimeout(() => {
          this.setState({
            login_error: false
          });
        }, 4000);
      });
  };

  _handleCreateInputChange = (name, e) => {
    this.setState(
      {
        [name]: e.target.value
      },
      () => {
        const { email, first_name, last_name, phone, password, password_confirmation } = this.state;
        const create_account_errors = [];

        if (FormUtils._validateEmail(email)) {
          AnnuitiesApi.checkNewMemberEmail(email).then(response => {
            this.setState({
              account_exists: response.data.member_exists,
              current_view: response.data.member_exists ? 'login' : this.state.current_view
            });
          });
        }

        PASSWORD_RULES.forEach(rule => {
          if (!rule.pattern.test(password)) {
            create_account_errors.push(rule.name);
          }
        });

        if (!FormUtils._validateEmail(email)) {
          create_account_errors.push('email');
        }

        if (!first_name) {
          create_account_errors.push('first_name_existance');
        }

        if (first_name && first_name.length < 2) {
          create_account_errors.push('first_name');
        }

        if (!last_name) {
          create_account_errors.push('first_name_existance');
        }

        if (last_name && last_name.length < 2) {
          create_account_errors.push('last_name');
        }

        if (!phone) {
          create_account_errors.push('phone_existance');
        }

        if (phone && phone.length !== 10) {
          create_account_errors.push('phone');
        }

        if (password !== password_confirmation) {
          create_account_errors.push('password_confirmation');
        }

        this.setState({ create_account_errors });
      }
    );
  };

  _createAccount = () => {
    const { email, first_name, last_name, phone, password, password_confirmation } = this.state;
    this.setState({ saving: true });

    AnnuitiesApi.register({ email, password, first_name, last_name, phone, password, password_confirmation }).then(response => {
      this.setState({
        create_account_errors: []
      });

      Cookies.set(
        'ixn',
        {
          access_token: response.headers['access-token'],
          client: response.headers['client'],
          uid: response.headers['uid'],
          id: response.data.id
        },
        { domain: window.location.hostname }
      );

      this.context.handleAgentLogin(response.data);
      this.props.onLogin(response.data);
      this.setState({ saving: false });
    });
  };

  _handleLogin = agent => {
    const cookied_info = Cookies.getJSON('ixn_clementine_alq') || {};

    Cookies.set(
      'ixn_clementine_alq',
      Object.assign({}, cookied_info, {
        agent
      }),
      { domain: window.location.hostname }
    );

    this.context.handleAgentLogin(agent);
    this.props.onLogin(agent);
  };

  render() {
    const styles = this.styles();
    const { account_exists, create_account_errors, current_view, email, first_name, last_name, login_error, password, password_confirmation, phone, saving } = this.state;

    if (current_view === 'login') {
      return (
        <div style={styles.content}>
          {account_exists ? <div style={styles.note}>An account already exists with the email you provided. Please login to access it.</div> : null}
          <div style={styles.title}>Login with your IXN Account</div>
          <div style={styles.description}>
            {this.props.loginDescription || "Once logged in we'll pre-fill all agent information with information from your IXN Account. You'll also gain acces to additional tools and features."}
          </div>
          <Field label='Email'>
            <Input invalid={login_error} onChange={this._handleInputChange.bind(null, 'email')} required={true} type='email' value={email} />
          </Field>
          <Field label='Password'>
            <Input invalid={login_error} onChange={this._handleInputChange.bind(null, 'password')} required={true} type='password' value={password} />
          </Field>
          <Button color={login_error ? 'RED' : null} onClick={this._login}>
            {login_error ? 'Invalid Login' : 'Login'}
          </Button>
          <div onClick={this._toggleView.bind(null, 'create_account')} style={{ textAlign: 'center', fontSize: FontSizes.SMALL, cursor: 'pointer', marginTop: 20 }}>
            Don't have an account? <span style={styles.link}>Create One</span>
          </div>
        </div>
      );
    } else if (current_view === 'create_account') {
      return (
        <div style={styles.content}>
          <div style={styles.title}>Create an IXN Account</div>
          <Field label='Email'>
            <Input invalid={create_account_errors.includes('email')} onChange={this._handleCreateInputChange.bind(null, 'email')} required={true} type='email' value={email} />
          </Field>
          <Field label='First Name'>
            <Input invalid={create_account_errors.includes('first_name')} onChange={this._handleCreateInputChange.bind(null, 'first_name')} required={true} value={first_name} />
          </Field>
          <Field label='Last Name'>
            <Input invalid={create_account_errors.includes('last_name')} onChange={this._handleCreateInputChange.bind(null, 'last_name')} required={true} value={last_name} />
          </Field>
          <Field label='Phone'>
            <Input format='phone' invalid={create_account_errors.includes('phone')} onChange={this._handleCreateInputChange.bind(null, 'phone')} required={true} value={phone} />
          </Field>
          <Field label='Password'>
            <Input invalid={create_account_errors.includes('password')} onChange={this._handleCreateInputChange.bind(null, 'password')} required={true} type='password' value={password} />
          </Field>
          <Field label='Confirm Password'>
            <Input
              invalid={create_account_errors.includes('password_confirmation')}
              onChange={this._handleCreateInputChange.bind(null, 'password_confirmation')}
              required={true}
              type='password'
              value={password_confirmation}
            />
          </Field>
          <div style={{ margin: '20px 0 30px' }}>
            {PASSWORD_RULES.map((rule, index) => {
              const is_valid = !create_account_errors.includes(rule.name);

              return (
                <div key={`${rule.name}-${index}`} style={Object.assign({}, styles.password_rule, is_valid && styles.password_rule_valid)}>
                  {is_valid ? <i className='mdi mdi-checkbox-marked-circle-outline' style={styles.icon_green} /> : <i className='mdi mdi-checkbox-blank-circle-outline' style={styles.icon} />}{' '}
                  {rule.label}
                </div>
              );
            })}
          </div>
          {saving ? (
            <Button disabled={saving} icon={saving ? 'loading mdi-spin' : null} onClick={this._createAccount}>
              Saving...
            </Button>
          ) : (
            <Button disabled={create_account_errors.length} onClick={this._createAccount}>
              Create Account
            </Button>
          )}
          <div onClick={this._toggleView.bind(null, 'login')} style={{ textAlign: 'center', fontSize: FontSizes.SMALL, cursor: 'pointer', marginTop: 20 }}>
            <span style={styles.link}>Back to Login</span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  styles = () => {
    return {
      content: {
        width: 500,
        padding: '30px 80px 30px'
      },
      title: {
        fontFamily: FontFamilies.ACCENT,
        fontSize: FontSizes.XLARGE,
        fontWeight: FontWeights.MEDIUM,
        marginBottom: 20
      },
      description: {
        fontSize: FontSizes.REGULAR,
        lineHeight: '1.5em',
        marginBottom: 20
      },
      link: {
        color: this.context.color,
        fontWeight: FontWeights.MEDIUM
      },
      note: {
        padding: 10,
        fontSize: FontSizes.REGULAR,
        lineHeight: '1.5em',
        marginBottom: 30,
        borderRadius: 4,
        background: `rgba(${Colors.GREEN.rgb}, 0.1)`,
        border: `1px solid ${Colors.GREEN.hex}`
      },
      password_rule: {
        fontSize: FontSizes.REGULAR,
        marginBottom: 5
      },
      password_rule_valid: {
        color: '#999'
      },
      icon: {
        fontSize: 20,
        verticalAlign: 'middle',
        color: '#ccc',
        marginRight: 2
      },
      icon_green: {
        fontSize: 20,
        verticalAlign: 'middle',
        color: Colors.GREEN.hex,
        marginRight: 2
      }
    };
  };
}

AgentLoginForm.contextType = ProductContext;

export default AgentLoginForm;
