import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from '@hexure/ui';
import { mdiCheckboxMarkedCircleOutline } from '@mdi/js';

const VisualizeModal = ({ onClose, product }) => {
  return (
    <Modal
      onClose={onClose}
      primaryButton={{
        children: 'Click to Vizualize',
        icon: mdiCheckboxMarkedCircleOutline
      }}
      title={product.product_name}
    >
      <div> hola </div>
    </Modal>
  );
};
VisualizeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  quote: PropTypes.object.isRequired
};

export default VisualizeModal;
