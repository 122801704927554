import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '@mdi/react';
import { mdiCircleEditOutline } from '@mdi/js';
import { Copy } from '@hexure/ui';

import { ThemeContext } from 'shared/ThemeContext';

const Card = styled.div`
  flex: 1;
  background: #f9f9f9;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: ${props => `${props.$color}0d`};
  }
`;

const Header = styled.div`
  border-bottom: 1px solid #fff;
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
`;

const Badge = styled.div`
  display: flex;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  background: var(--red, #e82425);
  color: #fff;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 1px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  flex: 1;
`;

const Body = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
`;

const EmptyState = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 8px;
`;

const EmptyStateItem = styled.div`
  height: 16px;
  border-radius: 4px;
  background: ${props => `${props.$color}0d`};
  width: ${props => props.$width};
`;

const CriteriaCard = ({ children, errorCount, onClick, title }) => {
  const theme_context = useContext(ThemeContext);

  return (
    <Card $color={theme_context.color} onClick={onClick}>
      <Header>
        <Label>
          {errorCount ? <Badge>{errorCount}</Badge> : null}
          <Copy color={errorCount ? 'RED' : 'BLACK'} type='bold'>
            {title}
          </Copy>
        </Label>
        <Icon color={theme_context.color} path={mdiCircleEditOutline} size='22px' />
      </Header>
      {children ? (
        <Body>{children}</Body>
      ) : (
        <EmptyState>
          <EmptyStateItem $color={theme_context.color} $width='50%' />
          <EmptyStateItem $color={theme_context.color} $width='75%' />
          <EmptyStateItem $color={theme_context.color} $width='40%' />
          <EmptyStateItem $color={theme_context.color} $width='90%' />
        </EmptyState>
      )}
    </Card>
  );
};

CriteriaCard.propTypes = {
  errorCount: PropTypes.number,
  onClick: PropTypes.func,
  title: PropTypes.string
};

export default CriteriaCard;
