import React, { useContext } from 'react';
import styled, { keyframes } from 'styled-components';

import { ThemeContext } from 'shared/ThemeContext';

const loading = keyframes`
  0% { left: -30%; }
  100% { left: 130%; }
`;

const Loader = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0.1;
  background: linear-gradient(90deg, #fff, #bbb, #fff);
  width: 30%;
  animation-name: ${loading};
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Checkbox = styled.div`
  display: flex;
  padding: 16px 20px 0px 0px;
  align-items: flex-start;
  align-self: stretch;
`;

const Circle = styled.div`
  display: flex;
  width: 26px;
  height: 26px;
  flex-shrink: 0;
  background: #f1f1f1;
  border-radius: 13px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  overflow: hidden;
  background: #fcfcfc;
  flex: 1;
  position: relative;
`;

const Top = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  justify-content: space-between;
  background: ${props => `${props.$color}0d`};
`;

const TopLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 70%;
`;

const Bottom = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

const BottomLeft = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 6px;
  width: 50%;
`;

const BottomRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50%;
`;

const Rates = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  width: 50%;
`;

const Rate = styled.div`
  display: flex;
  padding: 12px 0px 0px 0px;
  justify-content: center;
  align-items: flex-end;
  gap: 4px;
  width: 50%;
`;

const Placeholder = styled.div`
  width: ${props => props.$width};
  height: ${props => props.$height};
  border-radius: 8px;
  background: ${props => `${props.$color}0d`};
  flex-shrink: 1;
`;

const LoadingQuote = () => {
  const { color } = useContext(ThemeContext);

  return (
    <Wrapper>
      <Checkbox>
        <Circle />
      </Checkbox>
      <Content>
        <Loader />
        <Top $color={color}>
          <TopLeft>
            <Placeholder $color={color} $height='30px' $width='25%' />
            <Placeholder $color={color} $height='30px' $width='75%' />
          </TopLeft>
          <Placeholder $color={color} $height='30px' $width='15%' />
        </Top>
        <Bottom>
          <BottomLeft>
            <Placeholder $color={color} $height='26px' $width='15%' />
            <Placeholder $color={color} $height='26px' $width='34%' />
            <Placeholder $color={color} $height='26px' $width='50%' />
          </BottomLeft>
          <BottomRight>
            <Placeholder $color={color} $height='40px' $width='50%' />
            <Rates>
              <Rate>
                <Placeholder $color={color} $height='18px' $width='50%' />
                <Placeholder $color={color} $height='14px' $width='50%' />
              </Rate>
              <Rate>
                <Placeholder $color={color} $height='18px' $width='50%' />
                <Placeholder $color={color} $height='14px' $width='50%' />
              </Rate>
            </Rates>
          </BottomRight>
        </Bottom>
      </Content>
    </Wrapper>
  );
};

export default LoadingQuote;
