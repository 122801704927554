import PropTypes from 'prop-types';
import React from 'react';

import { ProductContext } from 'annuities/Context';

import Modal from 'shared/Modal';
import Button from 'shared/Button';
import Input from 'shared/Input';
import Field from 'shared/Field';
import FieldGroup from 'shared/FieldGroup';

import { Colors, FontSizes, FontWeights } from 'constants/Clementine';

import AnnuitiesApi from 'utils/AnnuitiesApi';
import FormUtils from 'utils/Form';

import { SA_BASE_URL } from 'config/App';

class ShareQuotesModal extends React.Component {
  static propTypes = {
    actions: PropTypes.string,
    onBackClick: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    selectedQuotes: PropTypes.array.isRequired,
    setMessage: PropTypes.func.isRequired,
    setPhoneNumber: PropTypes.func.isRequired,
    setSubject: PropTypes.func.isRequired,
    setTo: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props);

    this.state = {
      loading: false,
      current_view: props.actions ? 'enter_information' : 'delivery_method',
      pdf_url: null,

      errors: [],

      agent_email: context.agent.email || '',
      agent_name: `${context.agent.first_name} ${context.agent.last_name}`,
      agent_phone_number: context.agent.phone || '',
      agent_license_number: context.agent.insurance_license_number || '',
      client_name: `${context.client.first_name} ${context.client.last_name}`,

      phone_number: '',
      message: 'Thank you for your interest in a life insurance policy! Take a look at your personalized insurance quotes.',
      to: '',
      subject: '',
      pdf_action: props.actions || 'download',
      template: 'default'
    };
  }

  _toggleView = current_view => {
    this.setState({
      current_view,
      errors: []
    });
  };

  _setPdfAction = pdf_action => {
    this.setState({ pdf_action });
  };

  _setTemplate = template => {
    this.setState({ template });
  };

  _handleFieldChange = (name, e) => {
    const { setTo, setPhoneNumber, setSubject, setMessage } = this.props;
    this.setState({
      [name]: e.target.value
    });
    switch (name) {
      case 'to':
        setTo(e.target.value);
        break;
      case 'phone_number':
        setPhoneNumber(e.target.value);
        break;
      case 'subject':
        setSubject(e.target.value);
        break;
      case 'message':
        setMessage(e.target.value);
        break;
      default:
        break;
    }
  };

  _generatePdf = () => {
    const { agent_email, agent_license_number, agent_name, agent_phone_number, client_name, message, pdf_action, phone_number, subject, to, template } = this.state;
    const errors = [];

    if (pdf_action === 'email') {
      if (!FormUtils._validateEmail(to)) {
        errors.push('to');
      }

      if (!FormUtils._validateEmail(agent_email)) {
        errors.push('agent_email');
      }

      if (subject.length < 4) {
        errors.push('subject');
      }

      if (message.length < 4) {
        errors.push('message');
      }
    }

    if (pdf_action === 'text') {
      if (!FormUtils._validatePhone(phone_number)) {
        errors.push('phone_number');
      }

      if (!message.length) {
        errors.push('message');
      }
    }

    if (errors.length) {
      this.setState({
        errors
      });
    } else {
      this.setState({
        loading: true,
        errors: []
      });

      AnnuitiesApi.generateQuotesPdf({
        quote_guids: [this.props.selectedQuotes.id],
        template,
        pdf_action,
        show_agent_info: true,
        agent_email,
        from: agent_email,
        agent_first_name: agent_name,
        agent_last_name: agent_name,
        agent_license: agent_license_number,
        agent_phone: agent_phone_number,
        to,
        client_name,
        phone_number,
        subject,
        message
      }).then(response => {
        this.setState({
          current_view: 'success',
          pdf_url: response.data.url,
          loading: false
        });
      });
    }
  };

  _openPdf = () => {
    window.open(this.state.pdf_url);
  };

  render() {
    const { onClose, onBackClick } = this.props;
    const { agent_email, agent_license_number, agent_name, agent_phone_number, client_name, current_view, errors, loading, message, pdf_action, phone_number, subject, to, template } = this.state;
    const styles = this.styles();
    const titles = {
      delivery_method: 'Select a Delivery Method',
      template: 'Select a PDF Layout',
      enter_information: 'Enter Information',
      success: ''
    };
    const buttons = {
      delivery_method: [
        {
          color: '#e5e5e5',
          fontColor: '#999',
          onClick: onClose,
          style: { margin: '0 10px 0 auto' },
          children: 'Cancel'
        },
        {
          onClick: this._toggleView.bind(null, 'template'),
          children: 'Next'
        }
      ],
      template: [
        {
          color: '#e5e5e5',
          fontColor: '#999',
          onClick: onBackClick || this._toggleView.bind(null, 'delivery_method'),
          style: { margin: '0 10px 0 auto' },
          children: 'Back'
        },
        {
          onClick: this._toggleView.bind(null, 'enter_information'),
          children: 'Next'
        }
      ],
      enter_information: [
        {
          color: '#e5e5e5',
          fontColor: '#999',
          onClick: onBackClick || this._toggleView.bind(null, 'template'),
          style: { margin: '0 10px 0 auto' },
          children: 'Back'
        },
        {
          disabled: loading,
          onClick: loading ? null : this._generatePdf,
          children: pdf_action === 'download' ? 'Download' : 'Send'
        }
      ]
    };
    const success_messages = {
      download: 'Your quote comparison PDF is ready. Click the button below to view and download it.',
      email: `Your quote comparison PDF has been delivered to ${to} on your behalf. Click the button below to view and download the PDF for your own records.`,
      text: `A link to your quote comparison PDF has been texted to ${FormUtils._formatAsPhone(phone_number)}. Click the button below to view and download the PDF for your own records.`
    };

    return (
      <Modal buttons={buttons[current_view]} loading={loading} maxWidth={1080} onClose={onClose} title={titles[current_view]}>
        {current_view === 'delivery_method' ? (
          <div style={styles.content}>
            <div style={styles.delivery_method_wrapper}>
              <div onClick={this._setPdfAction.bind(null, this.props.actions)} style={Object.assign({}, styles.box_button, pdf_action === this.props.actions && styles.box_button_selected)}>
                <i className='mdi mdi-file-download-outline' style={styles.box_button_icon} />
                <div style={styles.box_button_label}>{this.props.actions}</div>
              </div>
            </div>
          </div>
        ) : null}

        {current_view === 'template' ? (
          <div style={styles.content}>
            <div style={styles.template_wrapper}>
              <div onClick={this._setTemplate.bind(null, 'default')} style={styles.pdf_button}>
                <div
                  style={Object.assign({ backgroundImage: `url(${SA_BASE_URL}/ixn/images/classic.png)` }, styles.pdf_preview, { width: 260 }, template === 'default' && styles.box_button_selected)}
                />
                <div style={styles.box_button_label}>Detailed Comparison</div>
              </div>

              <div onClick={this._setTemplate.bind(null, 'clementine_condensed')} style={styles.pdf_button}>
                <div
                  style={Object.assign(
                    { backgroundImage: `url(${SA_BASE_URL}/ixn/images/clementine_condensed.png)` },
                    styles.pdf_preview,
                    template === 'clementine_condensed' && styles.box_button_selected
                  )}
                />
                <div style={styles.box_button_label}>Condensed Premium Comparison</div>
              </div>

              <div onClick={this._setTemplate.bind(null, 'clementine_by_premium')} style={styles.pdf_button}>
                <div
                  style={Object.assign(
                    { backgroundImage: `url(${SA_BASE_URL}/ixn/images/clementine_by_premium.png)` },
                    styles.pdf_preview,
                    template === 'clementine_by_premium' && styles.box_button_selected
                  )}
                />
                <div style={styles.box_button_label}>Premium Comparison</div>
              </div>

              <div onClick={this._setTemplate.bind(null, 'clementine_by_feature')} style={styles.pdf_button}>
                <div
                  style={Object.assign(
                    { backgroundImage: `url(${SA_BASE_URL}/ixn/images/clementine_by_feature.png)` },
                    styles.pdf_preview,
                    template === 'clementine_by_feature' && styles.box_button_selected
                  )}
                />
                <div style={styles.box_button_label}>Feature Comparison</div>
              </div>
            </div>
          </div>
        ) : null}

        {current_view === 'enter_information' ? (
          <div style={styles.content}>
            <FieldGroup label='Agent Information'>
              <Field label='Email'>
                <Input invalid={errors.includes('agent_email')} onChange={this._handleFieldChange.bind(null, 'agent_email')} required={pdf_action === 'email'} value={agent_email} />
              </Field>
              <Field label='Name'>
                <Input onChange={this._handleFieldChange.bind(null, 'agent_name')} value={agent_name} />
              </Field>
              <Field label='Phone'>
                <Input format='phone' onChange={this._handleFieldChange.bind(null, 'agent_phone_number')} value={agent_phone_number} />
              </Field>
              <Field label='License Number'>
                <Input onChange={this._handleFieldChange.bind(null, 'agent_license_number')} value={agent_license_number} />
              </Field>
            </FieldGroup>

            <FieldGroup label='Recipient Information'>
              <Field label='Name'>
                <Input onChange={this._handleFieldChange.bind(null, 'client_name')} value={client_name} />
              </Field>

              {pdf_action === 'text' ? (
                <Field label='Phone Number'>
                  <Input format='phone' invalid={errors.includes('phone_number')} onChange={this._handleFieldChange.bind(null, 'phone_number')} required={true} value={phone_number} />
                </Field>
              ) : null}

              {pdf_action === 'email' ? (
                <React.Fragment>
                  <Field label='Email'>
                    <Input invalid={errors.includes('to')} onChange={this._handleFieldChange.bind(null, 'to')} required={true} value={to} />
                  </Field>
                  <Field label='Subject'>
                    <Input invalid={errors.includes('subject')} onChange={this._handleFieldChange.bind(null, 'subject')} required={true} value={subject} />
                  </Field>
                </React.Fragment>
              ) : null}

              {pdf_action !== 'download' ? (
                <Field label='Message'>
                  <Input invalid={errors.includes('message')} onChange={this._handleFieldChange.bind(null, 'message')} required={true} style={{ height: 100 }} type='textarea' value={message} />
                </Field>
              ) : null}
            </FieldGroup>
          </div>
        ) : null}

        {current_view === 'success' ? (
          <div style={styles.content}>
            <div style={styles.success_wrapper}>
              <div style={styles.success_title}>Success!</div>
              <div style={styles.success_description}>{success_messages[pdf_action]}</div>
              <Button inline={true} onClick={this._openPdf}>
                View PDF
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
    );
  }

  styles = () => {
    return {
      content: {
        padding: 40,
        minWidth: 600
      },
      delivery_method_wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      box_button: {
        width: 120,
        height: 120,
        margin: '0 10px',
        padding: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        border: '1px solid #ccc',
        borderRadius: 4,
        cursor: 'pointer'
      },
      box_button_selected: {
        border: `2px solid ${this.context.color}`
      },
      box_button_icon: {
        fontSize: 40
      },
      box_button_label: {
        marginTop: 10,
        fontSize: FontSizes.MEDIUM,
        fontWeight: FontWeights.MEDIUM
      },
      template_wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
      },
      pdf_button: {
        width: 480,
        padding: '15px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      },
      pdf_preview: {
        width: 450,
        height: 335,
        border: '1px solid #ccc',
        borderRadius: 4,
        cursor: 'pointer',
        backgroundSize: 'cover',
        backgroundPosition: 'top left',
        backgroundRepeat: 'no-repeat',
        position: 'relative'
      },
      new: {
        position: 'absolute',
        top: -8,
        right: -6,
        fontSize: FontSizes.XSMALL,
        letterSpacing: 2,
        textTransform: 'uppercase',
        fontWeight: FontWeights.BOLD,
        color: '#fff',
        background: Colors.GREEN.hex,
        padding: '5px 8px',
        borderRadius: 3
      },
      success_wrapper: {
        textAlign: 'center',
        maxWidth: 600,
        margin: '0 auto'
      },
      success_title: {
        fontSize: FontSizes.XLARGE,
        fontWeight: FontWeights.MEDIUM,
        marginBottom: 20
      },
      success_description: {
        fontSize: FontSizes.MEDIUM,
        color: '#777',
        marginBottom: 40,
        lineHeight: '1.4em'
      }
    };
  };
}

ShareQuotesModal.contextType = ProductContext;

export default ShareQuotesModal;
