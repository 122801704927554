import PropTypes from 'prop-types';
import React from 'react';

import { ProductContext } from 'annuities/Context';

import Modal from 'shared/Modal';
import Button from 'shared/Button';
import Input from 'shared/Input';
import Field from 'shared/Field';
import FieldGroup from 'shared/FieldGroup';

import { FontSizes, FontWeights } from 'constants/Clementine';

import AnnuitiesApi from 'utils/AnnuitiesApi';
import FormUtils from 'utils/Form';

class RequestFormsModal extends React.Component {
  static propTypes = {
    onBackClick: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    quote: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props);

    this.state = {
      sending: false,
      current_view: 'enter_information',

      errors: [],

      agent_email: context.agent.email || '',
      agent_name: `${context.agent.first_name} ${context.agent.last_name}`,
      agent_phone_number: context.agent.phone || '',
      message: ''
    };
  }

  _toggleView = current_view => {
    this.setState({
      current_view,
      errors: []
    });
  };

  _handleFieldChange = (name, e) => {
    this.setState({
      [name]: e.target.value
    });
  };

  _requestForms = () => {
    const { agent_email, agent_name, agent_phone_number, message } = this.state;
    const errors = [];

    if (!FormUtils._validateEmail(agent_email)) {
      errors.push('agent_email');
    }

    if (message.length < 4) {
      errors.push('message');
    }

    if (errors.length) {
      this.setState({
        errors
      });
    } else {
      this.setState({
        sending: true,
        errors: []
      });

      AnnuitiesApi.requestForms({
        agent_name,
        agent_email,
        agent_phone_number,
        form_details: message,
        carrier_name: this.props.quote.carrier_name,
        product_name: this.props.quote.product_name,
        state: this.props.quote.state
      }).then(() => {
        this.setState({
          current_view: 'success',
          sending: false
        });
      });
    }
  };

  render() {
    const { onClose, onBackClick } = this.props;
    const { agent_email, agent_name, agent_phone_number, current_view, errors, sending, message } = this.state;
    const styles = this.styles();
    const titles = {
      enter_information: 'Enter Information',
      success: ''
    };
    const buttons = {
      enter_information: [
        {
          color: '#e5e5e5',
          fontColor: '#999',
          onClick: onBackClick,
          style: { margin: '0 10px 0 auto' },
          children: 'Back'
        },
        {
          disabled: sending,
          onClick: this._requestForms,
          children: 'Send'
        }
      ]
    };

    return (
      <Modal buttons={buttons[current_view]} loading={sending} maxWidth={800} onClose={onClose} title={titles[current_view]}>
        {current_view === 'enter_information' ? (
          <React.Fragment>
            <div style={styles.content}>
              <div style={styles.info_wrapper}>
                <FieldGroup label='Agent Information' style={{ width: '48%' }}>
                  <Field label='Email'>
                    <Input invalid={errors.includes('agent_email')} onChange={this._handleFieldChange.bind(null, 'agent_email')} required={true} value={agent_email} />
                  </Field>
                  <Field label='Name'>
                    <Input onChange={this._handleFieldChange.bind(null, 'agent_name')} value={agent_name} />
                  </Field>
                  <Field label='Phone'>
                    <Input format='phone' onChange={this._handleFieldChange.bind(null, 'agent_phone_number')} value={agent_phone_number} />
                  </Field>
                </FieldGroup>

                <FieldGroup label='Details' style={{ width: '48%' }}>
                  <Field label='What forms do you need?'>
                    <Input invalid={errors.includes('message')} onChange={this._handleFieldChange.bind(null, 'message')} required={true} style={{ height: 120 }} type='textarea' value={message} />
                  </Field>
                </FieldGroup>
              </div>
            </div>
          </React.Fragment>
        ) : null}

        {current_view === 'success' ? (
          <div style={styles.content}>
            <div style={styles.success_wrapper}>
              <div style={styles.success_title}>Success!</div>
              <div style={styles.success_description}>Your forms request has been submitted.</div>
              <Button inline={true} onClick={onClose}>
                Back To Results
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
    );
  }

  styles = () => {
    return {
      content: {
        paddingTop: 40
      },
      info_wrapper: {
        display: 'flex',
        justifyContent: 'space-between'
      },
      success_wrapper: {
        textAlign: 'center'
      },
      success_title: {
        fontSize: FontSizes.XLARGE,
        fontWeight: FontWeights.MEDIUM,
        marginBottom: 20
      },
      success_description: {
        fontSize: FontSizes.MEDIUM,
        color: '#777',
        marginBottom: 40,
        lineHeight: '1.4em'
      },
      link: {
        color: this.context.color,
        fontWeight: 600,
        textDecoration: 'underline',
        cursor: 'pointer',
        background: 'transparent'
      }
    };
  };
}

RequestFormsModal.contextType = ProductContext;

export default RequestFormsModal;
